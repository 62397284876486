import { useEffect } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router"

import { PrimaryBtn } from "../common/Buttons"
import { useQuery } from "../../lib/utils/utils"
import useRfqInfo from "../../lib/hooks/useRfqInfo"

const resultMsg = (mode, rfq, isSubmitValid) => {
  const now = new Date()
  const rfqDueTime = new Date(rfq.dueTime)

  let msg = ""
  if (!isSubmitValid && rfqDueTime > now) {
    msg = `If you have questions, please contact Harbour Plaza Hotels & Resorts`
    return (
      <>
        <span style={{ color: "#f1776c", fontSize: "26px" }}>
          Submit Failed.
        </span>
        <br />
        {msg}
      </>
    )
  }
  if (!isSubmitValid && rfqDueTime < now) {
    msg = `Sorry, this RFQ has ended, no submission is allowed. And submission will be declined because of late submission.`
    return (
      <>
        <span style={{ color: "#f1776c", fontSize: "26px" }}>
          Submit Failed.
        </span>
        <br />
        {msg}
      </>
    )
  }

  switch (mode) {
    case "submitted":
      msg = `Thank you, you have submitted successfully.`
      break
    case "decline":
      msg = `You have declined submission successfully.`
      break
    case "delete":
      msg = `You have deleted previous submission successfully.`
      break
    default:
      msg = `Thank you, you have submitted successfully.`
      break
  }
  return msg
}

const SubmissionResult = ({ mode, isSubmitValid, rfqId }) => {
  const navigate = useNavigate()
  const query = useQuery()
  const token = query?.token

  const { fetchRfq, rfq } = useRfqInfo()
  useEffect(() => {
    fetchRfq(rfqId)
  }, [fetchRfq])
  return (
    <>
      <Box
        sx={{
          maxWidth: "1240px",
          margin: "0 auto",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "1000px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              my: 5,
              color: "#2D3035",
              fontSize: "18px",
              fontWeight: "bold",
            }}
            variant="p"
          >
            {rfq && resultMsg(mode, rfq, isSubmitValid)}
          </Typography>
        </Stack>
        <PrimaryBtn
          btnLabel="Back"
          handleClick={() => navigate(`/supplier?token=${token}`)}
        />
      </Box>
    </>
  )
}

export default SubmissionResult
