import { useEffect, useState } from "react"
import { Divider, CircularProgress, Box, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router"

import MPage from "../../../components/common/MPage"
import SubmissionResult from "../../../components/Submission/SubmissionResult"
import { useSupplier } from "../../../lib/hooks/useSupplier"
import { PrimaryBtn } from "../../../components/common/Buttons"
import useRfqInfo from "../../../lib/hooks/useRfqInfo"

const SubmissionResultPage = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [isSubmitValid, setIsSubmitValid] = useState(true)
  const [mode, setMode] = useState(params.get("mode"))
  const token = params.get("token")

  const useSupplierHook = useSupplier()
  const { supplierInfo, fetchSupplierInfo, fetchAdobeSignComplete, isLoading } =
    useSupplierHook

  useEffect(() => {
    fetchSupplierInfo()
  }, [fetchSupplierInfo])

  useEffect(() => {
    let adobeSignCompleteResp
    const getAdobeSignResult = async () => {
      adobeSignCompleteResp = await fetchAdobeSignComplete()

      if (!adobeSignCompleteResp.data.status) {
        setIsSubmitValid(false)
        setMode("errorComplete")
      }
    }
    if (
      supplierInfo &&
      mode !== "decline" &&
      mode !== "delete" &&
      mode !== "error"
    ) {
      getAdobeSignResult()
      console.log("fetch")
    }
  }, [supplierInfo, isSubmitValid, setMode])
  return (
    <>
      <MPage title="Quotation Submission">
        <Box
          className="rfq-info-wrapper"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
          sx={{ maxWidth: "1240px", margin: "0" }}
        >
          {isLoading && (
            <>
              <Typography
                sx={{
                  my: 5,
                  color: "#2D3035",
                  fontSize: "18px",
                  fontWeight: "bold",
                  margin: "20px",
                }}
                variant="p"
              >
                Please wait for processing to complete...
              </Typography>
              <CircularProgress sx={{ margin: "20px", alignSelf: "center" }} />
            </>
          )}
          {!isLoading && mode === "error" && (
            <>
              <Typography
                sx={{
                  my: 5,
                  color: "#2D3035",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
                variant="div"
              >
                <span style={{ color: "#f1776c", fontSize: "26px" }}>
                  Submit Failed.
                </span>
                <br />
                If you have questions, please contact Harbour Plaza Hotels &amp;
                Resorts
              </Typography>
              <PrimaryBtn
                btnLabel="Back"
                handleClick={() => navigate(`/supplier?token=${token}`)}
              />
            </>
          )}
          {!isLoading && mode !== "error" && supplierInfo && (
            <>
              <Divider sx={{ margin: "20px 0" }} />
              <SubmissionResult
                mode={mode}
                useSupplierHook={useSupplierHook}
                isSubmitValid={isSubmitValid}
                rfqId={supplierInfo.rfqId}
              />
            </>
          )}
        </Box>
      </MPage>
    </>
  )
}

export default SubmissionResultPage
